import { useEffect } from "react";
import Footer from "../layout/footer";
import Header from "../layout/header";
import Head from "next/head";
import Builder from "../component/builder";

function Home({ post }) {
  useEffect(() => {
    document.querySelector("body").setAttribute("color", "color_1");
  }, []);

  return (
    <>
      <Head>
        <title>الذكاء الإلكتروني - eSmart</title>
        <link rel="canonical" href="https://esmart.sa" key="canonical" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://esmart.sa",
            "logo": "https://esmart.sa/images/logo.png"
          }`,
          }}
          key="product-jsonld"
        />
      </Head>
      <Header />

      <div className="page-builder page-content bg-white" id="top">
        {post.data.blcoks.map((item, index) => {
          return <Builder key={index} item={item} />;
        })}
      </div>
      <Footer />
    </>
  );
}

export async function getServerSideProps() {
  const res = await fetch(
    "https://backend.esmart.sa/api/pages/home?page_type=home",
    { next: { revalidate: 300 }, headers: { "User-Agent": "eSmartWebSite" } }
  );
  if (!res.ok) {
    return {
      redirect: {
        destination: `/500`,
        permanent: false,
      },
    };
  }
  const post = await res.json();

  if (!post.data) {
    return {
      redirect: {
        destination: `/`,
        permanent: false,
      },
    };
  }

  return {
    props: {
      post,
    },
  };
}
export default Home;
